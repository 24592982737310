/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyASV52lDsf8cl3BZdUYa3ufh6ZYgERcwdM",
  "appId": "1:976273177259:web:15fc084f311c5cc157d5ee",
  "authDomain": "schooldog-i-whitfield-ga.firebaseapp.com",
  "measurementId": "G-9XVS0PXN3C",
  "messagingSenderId": "976273177259",
  "project": "schooldog-i-whitfield-ga",
  "projectId": "schooldog-i-whitfield-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-whitfield-ga.appspot.com"
}
